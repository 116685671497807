import React from "react";
import List from "@material-ui/core/List";
import {Button} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import {useHistory} from "react-router-dom";

const ListItems = (props) => {
    const history = useHistory();
    const {link, listname, icon, toggleDrawer, anchor} = props;
    const handleLocation = () => {
        history.push(link);
    };
    return (
        <List>
            <ListItem
                style={{
                    display: "flex",
                    paddingTop: '0px',
                    paddingBottom: '0px'
                }}>
                <Button
                    fullWidth
                    style={{
                        justifyContent: "flex-start",
                        fontWeight: "medium",

                        py: 1.25,
                        textTransform: "UperCase",
                        width: "100%",
                        "& svg": {
                            mr: 1,
                        }
                    }}
                    onBlur={handleLocation}
                    onClick={toggleDrawer(anchor, false)}
                >
                    {icon}&nbsp;
                    <span>{listname}</span>
                </Button>
            </ListItem>
        </List>
    )
};

export default ListItems;