import React from "react";
import Grid from "@material-ui/core/Grid";

const Card = (props) => {
    const {number, carddata, colors} = props;

    return (
        <React.Fragment>
            <Grid item xs={12} sm={4}>
                <div className="member" style={{backgroundColor: (`${colors}`)}}>
                    <div className="l">
                        <div className="social"/>
                    </div>
                    <div className="member-info">
                        <h1 style={{color: 'white'}}>{number}</h1>
                        <h4 style={{color: 'white'}}>{carddata}</h4>
                    </div>

                </div>
            </Grid>
        </React.Fragment>
    );
};
export default Card;
