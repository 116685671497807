import React, {useState, useCallback, useEffect} from "react";
import {helpers} from "../../helper/helper";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchLayout from "../../layouts/SearchLayout";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useSnackbar} from "notistack";
const sortIcon = <ArrowDownward/>;

const UsersLists = ({userList}) => {
    const {enqueueSnackbar} = useSnackbar();
    const [filterText, setFilterText] = useState("");
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState('');
    const [subList, setSubList] = useState();
    const [subId, setSubId] = React.useState('');

    const handleChange = (event) => {
        setSubId(event.target.value);
    };

    const SubData = useCallback(async () => {
        const url = "plans";
        const result = await helpers.apiCall("GET", url);
        setSubList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setSubList]);

    useEffect(() => {
        SubData();
    }, [SubData]);

    const handleClickOpen = (value) => {
        setOpen(true);
        setId(value)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filteredItems = userList && userList.result ? userList.result.filter((item) => {
        let search = filterText.toLowerCase();
        return item._id && item._id.toLowerCase().includes(search);
    }) : '';

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <SearchLayout onFilter={setFilterText} placeHolderText="Search by Name"/>
        );
    }, [setFilterText]);


    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("planId", subId ? subId : '');
        formData.append("userId", id ? id : '');
        const Data = {
            planId: subId ? subId : '',
            userId: id ? id : ''
        };
        const url = "users/admin/plan";
        const result = await helpers.apiCall("POST", url, Data);
        if (result.status === 200) {
            enqueueSnackbar("Give away updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            setSubId();
            handleClose();
           
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const headingAdmin = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Name",
            selector: "name",
            wrap: true,
            sortable: true,
        }, {
            name: "Email",
            selector: "email",
            wrap: true,
            sortable: true,
        }, {
            name: " ",
            selector: "_id",
            right: true,
            cell: (userList) => (
                <Button variant="contained" color="primary"
                        onClick={e => handleClickOpen(userList._id)}>GiveAway</Button>
            )
        }
    ];
    return (
        <React.Fragment>

            <DataTable
                columns={headingAdmin}
                data={filteredItems}
                defaultSortField="name"
                pagination
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                sortIcon={sortIcon}
                subHeader
                noHeader={true}
                subHeaderWrap={false}
                subHeaderAlign="right"
                subHeaderComponent={[subHeaderComponentMemo]}
                striped={true}
                highlightOnHover={true}
                responsive={true}

            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"SELECT SUBSCRIPTION"}</DialogTitle>
                <DialogContent>
                    <FormControl style={{width: '110px'}}>
                        <InputLabel id="demo-simple-select-label">Plans</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subId}
                            onChange={handleChange}
                        >
                            {subList ? subList.map((data) => (

                                <MenuItem value={data._id}>{data.validity}</MenuItem>

                            )) : ''}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
};

export default UsersLists;