import React, {useState, useCallback, useEffect} from "react";
import {helpers} from "../../../helper/helper";
import {Paper, Grid, Typography, Button} from "@material-ui/core";
import PageContainer from "../../../layouts/PageContainer";
import {Formik, Form} from "formik";
import UpdateForm from "./updateForm";
import {makeStyles} from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import Loader from "../../../layouts/Loader";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const FeedbackContainer = (props) => {
    const classes = useStyles();
    const Id = props.match.params.id;
    const initialValues = {
        status: "true",
        adminRemark: "",

    };
    const [feedbackData, setFeedbackData] = useState();
    const [statusV, setStatusV] = React.useState('true');
    const [state, setState] = React.useState({
        checkedA: true,

    });
    const [check, setCheck] = useState(false);
    const handleChangeToggle = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const handleChanges = (event) => {
        setStatusV(event.target.value);
    };

    const FeedbackData = useCallback(async () => {
        const url = `feedback/${Id}`;
        const result = await helpers.apiCall("GET", url);
        setFeedbackData(result.data.result);
    }, [setFeedbackData, Id]);

    const handleSubmit = async (values) => {
        setCheck(true);
        const feedbackdetail = getdetail(values);
        const url = `feedback/${Id}`;
        const result = await helpers.apiCall("PUT", url, feedbackdetail);
        if (result.status === 200) {
            FeedbackData();
            setCheck(false)
        }
    };

    useEffect(() => {
        FeedbackData();
    }, [FeedbackData]);

    return (
        <PageContainer pageheading="FEEDBACK">
            {check === true ? <Loader/> :
                <Paper elevation={3} style={{width: "100%"}}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Title - {feedbackData && feedbackData.title ? feedbackData.title : ''}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; Reported By
                                - &nbsp;&nbsp;{feedbackData && feedbackData.reportedby && feedbackData.reportedby.email ? feedbackData.reportedby.email : ''}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; description
                                - &nbsp;&nbsp;{feedbackData && feedbackData.description ? feedbackData.description : ''}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; adminRemark
                                - &nbsp;&nbsp;{feedbackData && feedbackData.adminRemark ? feedbackData.adminRemark : ''}
                            </Typography>

                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Typography
                                gutterBottom
                                style={{fontWeight: "bold", color: 'black'}}
                                color="textSecondary"
                            >
                                &nbsp; status
                                - {feedbackData && feedbackData.status ? (feedbackData.status === true ? "True" : "False" ) : 'False'}
                            </Typography>

                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <hr/>
                    <label>&nbsp;&nbsp;Mark status</label>
                    <Switch
                        checked={state.checkedA}
                        onChange={handleChangeToggle}
                        name="checkedA"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                    <br/>
                    {state.checkedA === true ?
                        <Formik
                            enableReinitialize="true"
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {(formik) => {
                                return (
                                    <Form autoComplete="off">
                                        <UpdateForm formik={formik} setStatusV={setStatusV} statusV={statusV}
                                                    handleChanges={handleChanges}/>
                                        <Grid align="right" item style={{marginTop: "10px"}}>
                                            <div className={classes.wrapper}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className={classes.submit}
                                                    color="primary"
                                                >
                                                    {"Update Status"}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                        : ''}

                </Paper>
            }
        </PageContainer>
    )
}

export default FeedbackContainer;


const getdetail = (values) => {
    let feedbackDetails = {};

    feedbackDetails = {
        status: (values.status === "true" ? true : false),
        adminRemark: values.adminRemark

    };
    return feedbackDetails;


};