import React from "react";

import {Grid, TextField} from "@material-ui/core";

const CreatePlanForm = ({formik}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="amount"
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    onChange={handleChange}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="validity"
                    name="validity"
                    label="Validity"
                    value={values.validity}
                    onChange={handleChange}
                    error={touched.validity && Boolean(errors.validity)}
                    helperText={touched.validity && errors.validity}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="bonus"
                    name="bonus"
                    label="Bonus"
                    value={values.bonus}
                    onChange={handleChange}
                    error={touched.bonus && Boolean(errors.bonus)}
                    helperText={touched.bonus && errors.bonus}
                />
            </Grid>
        </Grid>
    );
};

export default CreatePlanForm;
