import React, {useState, useCallback, useEffect} from "react";
import {helpers} from "../../helper/helper";

import {Box, Card, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import {Avatar, Typography} from '@material-ui/core';

const user = {
    avatar: '/static/images/avatars/avatar_6.png',
    city: 'Los Angeles',
    country: 'USA',
    jobTitle: 'Senior Developer',
    name: 'Katarina Smith',
    timezone: 'GTM-7'
};

const UserContainer = (props) => {
    const [values, setValues] = useState({
        firstName: 'Katarina',
        lastName: 'Smith',
        email: 'demo@devias.io',
        phone: '',
        state: 'Alabama',
        country: 'USA'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const Id = props.match.params.id;
    const [userData, setUserData] = useState();
    const [newDate, setNewDate] = useState();

    const UsersData = useCallback(async () => {
        const url = `users/${Id}`;
        const result = await helpers.apiCall("GET", url);
        setUserData(result.data);
        if (result.data.result.isSubscribed) {
            let dates = result.data.result.subscription[0].createdAt.split("t");
            let dayscount = result.data.result.subscription[0].planId.validity;
            let newdate = addDays(dates[0], parseInt(dayscount));
            setNewDate(newdate);
        }

    }, [setUserData, Id]);

    useEffect(() => {
        UsersData();
    }, [UsersData]);

    const addDays = (dates, days) => {
        let date = new Date(dates);
        date.setDate(date.getDate() + days);
        return date;
    };

    return (
        <React.Fragment>
            <Card {...props} style={{display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar style={{width: '100px', height: '100px'}}
                                src={userData && userData.result && userData.result.profilePic ? userData.result.profilePic : user.avatar}
                                sx={{
                                    height: 100,
                                    width: 100
                                }}
                        />
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                        >
                            {userData && userData.result && userData.result.name
                                ? userData.result.name
                                : "name"}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="body1"
                        >
                            {`${userData && userData.result && userData.result.district
                                ? userData.result.district
                                : "district"} ${userData && userData.result && userData.result.country
                                ? userData.result.country
                                : "Country"}`}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="body1"
                        >

                        </Typography>
                    </Box>
                </CardContent>
                <Divider/>

            </Card>

            &nbsp;&nbsp;
            <form
                autoComplete="off"
                noValidate
                {...props}
                style={{display: 'inline-block', width: '100%'}}
            >
                <Card>
                    <CardHeader
                        subheader="The information can be edited"
                        title="Profile"
                    />
                    <Divider/>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Age"
                                    required
                                    value={userData && userData.result && userData.result.age
                                        ? userData.result.age
                                        : "Age"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Gender"
                                    onChange={handleChange}
                                    required
                                    value={userData && userData.result && userData.result.gender
                                        ? userData.result.gender
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="D.O.B"
                                    required
                                    value={userData && userData.result && userData.result.dob
                                        ? new Date(userData.result.dob).toLocaleDateString()
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="phone"
                                    onChange={handleChange}
                                    type="number"
                                    value={userData && userData.result && userData.result.mobile
                                        ? parseInt(userData.result.mobile)
                                        : "11"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="REFERCODE"

                                    required
                                    value={userData && userData.result && userData.result.referCode
                                        ? userData.result.referCode
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="FOLLOWERS"

                                    required
                                    value={userData && userData.result && userData.result.followers
                                        ? userData.result.followers.length
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="FOLLOWING"

                                    required
                                    value={userData && userData.result && userData.result.followings
                                        ? userData.result.followings.length
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="SUBSCRIBED"
                                    required
                                    value={userData && userData.result && userData.result.isSubscribed
                                        ? userData.result.isSubscribed === true ? "True" : ''
                                        : "False"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Expires On"
                                    required
                                    value={newDate ? new Date(newDate).toLocaleDateString() : ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="CREATED AT"
                                    required
                                    value={userData && userData.result && userData.result.createdAt
                                        ? new Date(userData.result.createdAt).toLocaleDateString()
                                        : "Gender"}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    required
                                    value={userData && userData.result && userData.result.email
                                        ? userData.result.email
                                        : "Email"}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider/>
                </Card>
            </form>
        </React.Fragment>
    );
};

export default UserContainer;
