import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button,} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import {useSnackbar} from "notistack";

import CreateGiftsForm from "./CreateGiftsForm";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const CreateGifts = ({GiftsData, editCheck, singleGifts, editGifts, file, setFile, setSingleGifts, image, setImage}) => {
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const [img, setImg] = useState(false);
    const initialValues = {
        zole: "",
        popularity: "",
        giftImg: "",
    };
    const editValues = {
        zole: singleGifts && singleGifts.zole ? singleGifts.zole : "",
        popularity:
            singleGifts && singleGifts.popularity ? singleGifts.popularity : "",
        giftImg: singleGifts && singleGifts.giftImg ? singleGifts.giftImg : "",
    };

    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("zole", values.zole);
        formData.append("popularity", values.popularity);
        formData.append("giftImg", image ? image[0] : values.giftImg);

        const url = "gifts";
        const result = await helpers.apiCallForm("POST", url, formData);
        if (result.status === 200) {
            GiftsData();
            setImg(true);
            enqueueSnackbar("Gifts Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
        setImg(false)
    };

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editGifts : handleSubmit}
            >
                {(formik) => {
                    console.log(formik);
                    return (
                        <Form autoComplete="off">
                            <CreateGiftsForm formik={formik} setFile={setFile} file={file} singleGifts={singleGifts}
                                             setSingleGifts={setSingleGifts}
                                             image={image}
                                             setImage={setImage} img={img}/>
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "Edit" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreateGifts;
