import React from "react";
import Dropzone from "../../../layouts/Dropzone/Dropzone"
import {Grid, TextField} from "@material-ui/core";

const CreateGiftsForm = ({formik, image, setImage}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="zole"
                    name="zole"
                    label="zole"
                    value={values.zole}
                    onChange={handleChange}
                    error={touched.zole && Boolean(errors.zole)}
                    helperText={touched.zole && errors.zole}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="popularity"
                    name="popularity"
                    label="popularity"
                    value={values.popularity}
                    onChange={handleChange}
                    error={touched.popularity && Boolean(errors.popularity)}
                    helperText={touched.popularity && errors.popularity}
                />
            </Grid>
            <Grid sm={12} xs={12} item>
                <Dropzone acceptType="image/*" files={image} setFiles={setImage}
                          imgSrc={image && image.length ? "" : values.giftImg ? values.giftImg : ''} title="Image"
                          caption="Drag 'n' drop image files here, or click to select image files "/>
            </Grid>
        </Grid>
    );
};

export default CreateGiftsForm;
