import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import CreateTask from "./CreateTask";
import {Grid} from "@material-ui/core";
import TaskList from "./TaskList";
import {helpers} from "../../helper/helper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useSnackbar} from "notistack";

const Task = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [ChannelsList, setChannelsList] = useState();
    const [check, setCheck] = useState(false);
    const [singleGifts, setSingleGifts] = useState();
    const [giftsId, setGiftsId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [file, setFile] = useState();
    const [image, setImage] = useState([]);
    const [rewardTypes, setRewardTypes] = React.useState('');

    const TaskData = useCallback(async () => {
        const url = "tasks";
        const result = await helpers.apiCall("GET", url);
        setChannelsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setChannelsList]);

    useEffect(() => {
        TaskData();
    }, [TaskData]);

    const editChannels = async (values, {resetForm}) => {
        let formData = new FormData();
        formData.append('title', values.title);
        formData.append('link', values.link);
        formData.append('image', image[0] ? image[0] : singleGifts.image);
        formData.append("reward", values.reward);
        formData.append("rewardType", rewardTypes ? rewardTypes : '');
        const url = `tasks/${giftsId}`;
        const result = await helpers.apiCallForm("PUT", url, formData);
        if (result.status === 200) {
            TaskData();
            setEditCheck(false);
            setSingleGifts();
            setImage([]);
            enqueueSnackbar("Updated  successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singleGiftsById = async (id) => {
        setGiftsId(id);
        const url = `tasks/${id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSingleGifts(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    };

    const deleteGifts = async (id) => {
        const url = `tasks/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            TaskData();
            enqueueSnackbar("Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    const handleChange = () => {
        setCheck(!check);
    };

    return (
        <PageContainer pageheading="Task">
            <Grid container spacing={3}>
                <Grid item sm={4} xs={12}>
                    <CreateTask
                        ChannelsData={TaskData}
                        editCheck={editCheck}
                        singleGifts={singleGifts}
                        editChannels={editChannels}
                        setFile={setFile}
                        file={file}
                        setSingleGifts={setSingleGifts}
                        image={image}
                        setImage={setImage}
                        setRewardTypes={setRewardTypes}
                        rewardTypes={rewardTypes}
                    />
                </Grid>
                <Grid item sm={8} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Tasks List"
                        />
                    </FormGroup>

                    <TaskList
                        channelsList={ChannelsList}
                        deleteGifts={deleteGifts}
                        editChannels={editChannels}
                        singleGiftsById={singleGiftsById}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Task;
