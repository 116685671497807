import './App.css';
import {BrowserRouter} from "react-router-dom";
import Router from "./routes"
import {SnackbarProvider} from "notistack";

function App() {
    return (
        <SnackbarProvider>
            <BrowserRouter>
                <Router/>
            </BrowserRouter>
        </SnackbarProvider>

    );
}

export default App;
