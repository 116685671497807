import React from "react";
import {Switch, Route, withRouter, Redirect} from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Header from "../components/Header/Header"
import Login from "../pages/Auth/Login/Login"
import Footer from "../components/Footer/Footer";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import Users from "../pages/Users/index"
import UserContainer from "../pages/Users/UserContainer";
import "../App.css"
import Plan from "../pages/Plan/index"
import Gifts from "../pages/Gifts";
import Subscription from "../pages/Subscription";
import Banner from "../pages/Banner";
import Feedback from "../pages/Feedback";
import FeedbackContainer from "../pages/Feedback/FeedbackContainer.jsx";
import GiveAway from "../pages/GiveAway";
import Channels from "../pages/Channel";
import Task from "../pages/Task";
import Zole from "../pages/Zole";
import PaymentHistory from "../pages/PaymentHistory";
import PaymentHistoryContainer from "../pages/PaymentHistory/PaymentHistoryContainer"

const Router = (props) => {
    const token = localStorage.getItem('token');
    return (
        <React.Fragment>
            {props.location.pathname !== "/login" && props.location.pathname !== "/forget-password" ?
                <Header/>
                : null}
            <Switch>
                <Route exact path="/">
                    {token !== null ? <Redirect to="/dashboard"/> : <Redirect to="/login"/>}
                </Route>
            </Switch>
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/forget-password" component={ForgetPassword}/>
            </Switch>
            {token && token.length ?
                <Switch>
                    <div className="App">
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/dashboard/users" component={Users}/>
                        <Route exact path="/dashboard/users/:id" component={UserContainer}/>
                        <Route exact path="/dashboard/plans" component={Plan}/>
                        <Route exact path="/dashboard/gifts" component={Gifts}/>
                        <Route exact path="/dashboard/sub" component={Subscription}/>
                        <Route exact path="/dashboard/banner" component={Banner}/>
                        <Route exact path="/dashboard/feedback" component={Feedback}/>
                        <Route exact path="/dashboard/feedback/:id" component={FeedbackContainer}/>
                        <Route exact path="/dashboard/giveaway" component={GiveAway}/>
                        <Route exact path="/dashboard/channel" component={Channels}/>
                        <Route exact path="/dashboard/tasks" component={Task}/>
                        <Route exact path="/dashboard/zole" component={Zole}/>
                        <Route exact path="/dashboard/payment-history" component={PaymentHistory}/>
                        <Route exact path="/dashboard/payment-history/:id" component={PaymentHistoryContainer}/>
                    </div>
                </Switch>
                :
                <Switch>
                    <Redirect to="/login"/>
                </Switch>
            }

            <Footer/>
        </React.Fragment>
    )
};

export default withRouter(Router);