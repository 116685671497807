import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import CreateChannels from "./CreateChannel";
import {Grid} from "@material-ui/core";
import ChannelList from "./ChannelList";
import {helpers} from "../../helper/helper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const Channels = () => {
    const [ChannelsList, setChannelsList] = useState();
    const [check, setCheck] = useState(false);
    const [singleGifts, setSingleGifts] = useState();
    const [giftsId, setGiftsId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [file, setFile] = useState();
    const [image, setImage] = useState([]);

    const ChannelsData = useCallback(async () => {
        const url = "channel";
        const result = await helpers.apiCall("GET", url);
        setChannelsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setChannelsList]);

    useEffect(() => {
        ChannelsData();
    }, [ChannelsData]);

    const editChannels = async (values, {resetForm}) => {
        let formData = new FormData();
        formData.append('title', values.title);
        formData.append('link', values.link);
        formData.append('image', image[0] ? image[0] : singleGifts.image);
        const url = `channel/${giftsId}`;
        const result = await helpers.apiCallForm("PUT", url, formData);
        if (result.status === 200) {
            ChannelsData();
            setEditCheck(false);
            setSingleGifts();
            setImage([]);
        }
    };

    const singleGiftsById = async (id) => {
        setGiftsId(id);
        const url = `channel/${id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSingleGifts(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    };

    const deleteGifts = async (id) => {
        const url = `channel/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            ChannelsData();
        }
    };
    const handleChange = () => {
        setCheck(!check);
    };

    return (
        <PageContainer pageheading="Reward By Task">
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <CreateChannels
                        ChannelsData={ChannelsData}
                        editCheck={editCheck}
                        singleGifts={singleGifts}
                        editChannels={editChannels}
                        setFile={setFile}
                        file={file}
                        setSingleGifts={setSingleGifts}
                        image={image}
                        setImage={setImage}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Channels List"
                        />
                    </FormGroup>

                    <ChannelList
                        channelsList={ChannelsList}
                        deleteGifts={deleteGifts}
                        editChannels={editChannels}
                        singleGiftsById={singleGiftsById}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Channels;
