import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ListItems from "./ListItems";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FeedbackIcon from '@material-ui/icons/Feedback';
import RedeemIcon from '@material-ui/icons/Redeem';
import Chip from '@material-ui/core/Chip';
import {useHistory} from 'react-router-dom';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import img from "../../assets/img/Group 626.jpg"
import {Link} from "react-router-dom"
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import PaymentIcon from '@material-ui/icons/Payment';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
});

export default function Header() {
    const classes = useStyles();
    let history = useHistory();
    const [state, setState] = React.useState({
        left: false,
    });
    const tokenD = localStorage.getItem('user');
    const token = JSON.parse(tokenD);
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    const logout = () => {
        localStorage.clear();
        history.push('/')
    };

    return (
        <div>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <div>
                            <IconButton style={{display: 'inline-block'}} color="inherit" aria-label="open drawer"
                                        edge="start">
                                <MenuOpenIcon onClick={toggleDrawer(anchor, true)}/>
                            </IconButton>
                            <div noWrap style={{display: 'inline-block', verticalAlign: 'middle'}}>
                                <img src={img} alt="img" style={{width: '44%'}}/>
                            </div>
                            <div noWrap style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginLeft: '-60px',
                                fontSize: '20px'
                            }}>

                                <Link to="/dashboard" style={{textDecoration: 'none'}}>
                                    <span style={{color: '#F22853'}}>Zole</span>
                                    <span style={{color: '#F6A519'}}> Mate</span>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <Typography variant="h6" noWrap style={{display: 'inline-block'}}>
                                <Chip
                                    label={`${(token && token.scope ? token.scope.name.toUpperCase() : '')}  ${token && token.scope ? token.scope.role.toUpperCase() : ''}`}
                                    clickable
                                    color="primary"
                                    onDelete={logout}
                                    deleteIcon={< PowerSettingsNewIcon/>}
                                    variant="outlined"
                                />

                            </Typography>
                        </div>
                    </Toolbar>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <MenuOpenIcon
                            onClick={toggleDrawer(anchor, false)}
                            style={{marginLeft: "85%", marginTop: "6%"}}
                        />

                        <div className={classes.list}>
                            <ListItems
                                icon={<DashboardIcon/>}
                                listname={"Dashboard"}
                                link={"/dashboard"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<GroupIcon/>}
                                listname={"user"}
                                link={"/dashboard/users"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<BallotIcon/>}
                                listname={"Plans"}
                                link={"/dashboard/plans"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<CardGiftcardIcon/>}
                                listname={"Gifts"}
                                link={"/dashboard/gifts"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<SubscriptionsIcon/>}
                                listname={"Subscription"}
                                link={"/dashboard/sub"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<ViewCarouselIcon/>}
                                listname={"Banner"}
                                link={"/dashboard/banner"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<FeedbackIcon/>}
                                listname={"Feedback"}
                                link={"/dashboard/feedback"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<RedeemIcon/>}
                                listname={"GiveAway"}
                                link={"/dashboard/giveaway"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            {/* <ListItems
                                icon={<LiveTvIcon/>}
                                listname={"Channel"}
                                link={"/dashboard/channel"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            /> */}
                            <ListItems
                                icon={<AssignmentOutlinedIcon/>}
                                listname={"Reward By Tasks"}
                                link={"/dashboard/tasks"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<AmpStoriesIcon/>}
                                listname={"Zole"}
                                link={"/dashboard/zole"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <ListItems
                                icon={<PaymentIcon/>}
                                listname={"Payment History"}
                                link={"/dashboard/payment-history"}
                                toggleDrawer={toggleDrawer}
                                anchor={anchor}
                            />
                            <div onClick={logout}>
                                <ListItems
                                    icon={< PowerSettingsNewIcon/>}
                                    listname={"Logout"}

                                    toggleDrawer={toggleDrawer}
                                    anchor={anchor}
                                />
                            </div>
                        </div>
                    </Drawer>

                </React.Fragment>
            ))}
        </div>
    );
}
