import React from "react";
import {IconButton, LinearProgress, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        },
    },
}));

const SubscriptionList = ({subList, editPlan, deletePlan, check, singlePlanById}) => {
    const classes = useStyles();

    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Amount",
            selector: "amount",
            wrap: true,
            sortable: true,
        }, {
            name: "TimePeriod",
            selector: "timePeriod",
            wrap: true,
            sortable: true,
        },  {
            name: "Action",
            wrap: true,
            center: true,
            cell: (subList) => (
                <div>
                    <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                            singlePlanById(subList._id);
                        }}
                    >
                        <EditIcon color="primary"/>
                    </IconButton>

                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                            deletePlan(subList._id)
                        }}
                    >
                        <DeleteIcon color="primary"/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <div>
            {!subList ? (
                <LinearProgress color="primary"/>
            ) : (

                <Paper className={classes.paper} square>
                    {check ?
                        <DataTable
                            columns={heading}
                            noHeader={true}
                            data={subList}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            sortIcon={sortIcon}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                        />
                        : ''}
                </Paper>
            )}
        </div>
    );
};

export default SubscriptionList;
