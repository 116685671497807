import React, {useEffect, useState} from "react";

import PageContainer from "../../layouts/PageContainer";
import {helpers} from '../../helper/helper';
import {useCallback} from "react";
import PaymentHistoryList from "./PaymentHistoryList";
import Loader from "../../layouts/Loader";

const PaymentHistory = () => {

    const [historyList, setHistoryList] = useState();

    const PaymentData = useCallback(async () => {
        const url = "history";
        const result = await helpers.apiCall('GET', url);
        setHistoryList(result.data.result);
    }, [setHistoryList]);


    useEffect(() => {
        PaymentData()
    }, [PaymentData]);


    return (
        <PageContainer pageheading="USER LIST">
            {!historyList ? <Loader/> :
                <PaymentHistoryList historyList={historyList}/>
            }
        </PageContainer>
    )
};

export default PaymentHistory;