import React, {useEffect, useState} from "react";

import PageContainer from "../../layouts/PageContainer";
import {helpers} from '../../helper/helper';
import {useCallback} from "react";
import UsersList from "./UsersList";
import Loader from "../../layouts/Loader";

const Users = () => {

    const [userList, setUserList] = useState();

    const UsersData = useCallback(async () => {
        const url = "users";
        const result = await helpers.apiCall('GET', url);
        setUserList(result.data);
    }, [setUserList]);

    useEffect(() => {
        UsersData()
    }, [UsersData]);

    return (
        <PageContainer pageheading="USER LIST">
            {!userList ? <Loader/> :
                <UsersList userList={userList}/>
            }
        </PageContainer>
    )
};
export default Users;