import React, {useState} from "react";

import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchLayout from "../../layouts/SearchLayout";

const sortIcon = <ArrowDownward/>;

const UsersList = ({userList}) => {
    const [filterText, setFilterText] = useState("");

    const filteredItems =
        userList && userList.result
            ? userList.result.filter((item) => {
                let search = filterText.toLowerCase();
                let IDs = item._id && item._id.toLowerCase().includes(search);
                let name = item.name && item.name.toLowerCase().includes(search);
                return IDs || name
            })
            : "";

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <SearchLayout onFilter={setFilterText} placeHolderText="Search by Name"/>
        );
    }, [setFilterText]);

    const headingAdmin = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Name",
            selector: "name",
            wrap: true,
            sortable: true,
        }, {
            name: "Email",
            selector: "email",
            wrap: true,
            sortable: true,
        }, {
            name: " ",
            selector: "_id",
            right: true,
            cell: (userList) => (
                <Link to={"/dashboard/users/" + userList._id}>
                    <ChevronRightIcon/>
                </Link>
            )
        }
    ];
    return (
        <DataTable
            columns={headingAdmin}
            data={filteredItems}
            defaultSortField="name"
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            sortIcon={sortIcon}
            subHeader
            noHeader={true}
            subHeaderWrap={false}
            subHeaderAlign="right"
            subHeaderComponent={[subHeaderComponentMemo]}
            striped={true}
            highlightOnHover={true}
            responsive={true}
        />
    );
};
export default UsersList;
