import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import CreateSubscription from "./CreateSubscription";
import {Grid} from "@material-ui/core";
import SubscriptionList from "./SubscriptionList";
import {helpers} from "../../helper/helper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useSnackbar} from "notistack";

const Subscription = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [subList, setSubList] = useState();
    const [check, setCheck] = useState(true);
    const [singlePlan, setSinglePlan] = useState();
    const [planId, setPlanId] = useState();
    const [editCheck, setEditCheck] = useState(false);

    const SubData = useCallback(async () => {
        const url = "sub";
        const result = await helpers.apiCall("GET", url);
        setSubList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setSubList]);

    useEffect(() => {
        SubData();
    }, [SubData]);

    const editPlan = async (values) => {
        const url = `sub/${planId}`;
        const result = await helpers.apiCall("PUT", url, values);
        if (result.status === 200) {
            SubData();
            setEditCheck(false)
            enqueueSnackbar("Subscription Updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singlePlanById = async (id) => {
        setPlanId(id);
        const url = `sub/${id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSinglePlan(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    };

    const deletePlan = async (id) => {
        const url = `sub/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            SubData();
            enqueueSnackbar("Subscription Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    const handleChange = () => {
        setCheck(!check);
    };

    return (
        <PageContainer pageheading="CREATE SUBSCRIPTION">
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <CreateSubscription
                        SubData={SubData}
                        editCheck={editCheck}
                        singlePlan={singlePlan}
                        editPlan={editPlan}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={check} onChange={handleChange} name="check"/>
                            }
                            label="Show Plan List"
                        />
                    </FormGroup>

                    <SubscriptionList
                        subList={subList}
                        deletePlan={deletePlan}
                        editPlan={editPlan}
                        singlePlanById={singlePlanById}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Subscription;
