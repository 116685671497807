import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {Link, useHistory} from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoginForm from './LoginForm';
import {Form, Formik} from "formik";
import {helpers} from '../../../helper/helper';
import jwt_decode from "jwt-decode";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    const classes = useStyles();
    let history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const initialValues = {userInput: "", password: ""};

    const handleSubmit = async (values) => {

        const result = await helpers.login("POST", values);
        if (result.status === 200) {
            enqueueSnackbar("Loged in successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
            const token = jwt_decode(result.data.result.token);
            result.data && result.data.result && result.data.result.token && localStorage.setItem('token', result.data.result.token);
            token && localStorage.setItem('user', JSON.stringify(token));
            history.push('/dashboard');
        }
        else {
            enqueueSnackbar("Username/password Invalid", {
                variant: 'Error',
                autoHideDuration: 3000,
            });
            history.push('/login');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {

                        return (
                            <Form autoComplete>
                                <div className={classes.form} noValidate>
                                    <LoginForm formik={formik}/>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Sign In
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link to="/forget-password" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Form>
                        )
                    }}


                </Formik>
            </div>

        </Container>
    );
}