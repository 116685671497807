import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import {BannerValidation} from "../../../helper/ValidationSchema";
import CreateBannerForm from "./CreateBannerForm";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const CreateBanner = ({GiftsData, editCheck, singleGifts, file, setFile, setSingleGifts, image, setImage, setTypes, types, editBanner}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [img, setImg] = useState(false);

    const handleChanges = (event) => {
        setTypes(event.target.value);
    };
    const initialValues = {
        type: '',
        title: "",
        image: "",
        link: "",
    };

    const editValues = {
        type: singleGifts && singleGifts.type ? singleGifts.type : "",
        title: singleGifts && singleGifts.title ? singleGifts.title : "",
        link: singleGifts && singleGifts.link ? singleGifts.link : "",
        image: singleGifts && singleGifts.image ? singleGifts.image : "",
    };

    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("title", values.title ? values.title : types);
        formData.append("link", values.link);
        formData.append("image", image ? image[0] : null);
        formData.append("type", types ? types : null);

        const url = "banners";
        const result = await helpers.apiCallForm("POST", url, formData);
        if (result.status === 200) {
            GiftsData();
            setImg(true)
            enqueueSnackbar("Banner Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
        setImg(false)
    };

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck?editBanner:handleSubmit}
                validationSchema={
                    BannerValidation
                }
            >
                {(formik) => {

                    return (
                        <Form autoComplete="off">
                            <CreateBannerForm formik={formik} setFile={setFile} file={file} singleGifts={singleGifts}
                                              setSingleGifts={setSingleGifts}
                                              image={image}
                                              setImage={setImage} img={img} types={types}
                                              handleChanges={handleChanges}/>
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "Edit" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreateBanner;
