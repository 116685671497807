import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Button, Grid, TextField} from "@material-ui/core";

export default function ForgetForm({formik, handleOtp}) {

    return (
        <React.Fragment>
            <FormControl component="fieldset">
                <FormLabel component="legend" style={{color: 'grey'}}>Reset Your Password With Email Or Mobile
                    Number</FormLabel>

                <br/>
                <RadioGroup row aria-label="position" name="methode" defaultValue="top"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.methode}
                            formik={formik}>

                    <FormControlLabel value="email" control={<Radio color="primary"/>} label="Email"/>

                    <FormControlLabel value="phone" control={<Radio color="primary"/>} label="Phone"/>
                </RadioGroup>
            </FormControl>
            <Grid container spacing={0}>
                {formik.values.methode === "email" ?
                    <React.Fragment>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth

                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                formik={formik}
                            />
                        </Grid>
                        <Button onClick={e => handleOtp(formik.values.email, formik.values)} variant="contained"
                                color="primary" style={{
                            margin: 0,
                            lineHeight: 0,
                            height: "54px",
                            marginTop: "17px"
                        }}>
                            Get OTP
                        </Button>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Grid item xs={12} sm={9}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth

                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                formik={formik}
                            />

                        </Grid>
                        <Button variant="contained" onClick={e => handleOtp(formik.values.phone, formik.values)}
                                color="primary" style={{
                            margin: 0,
                            lineHeight: 0,
                            height: "54px",
                            marginTop: "17px"
                        }}>
                            Get OTP
                        </Button>
                    </React.Fragment>
                }

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="otp"
                        label="Enter Otp"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.otp}
                        formik={formik}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        formik={formik}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}