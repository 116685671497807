import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Dropzone from "../../../layouts/Dropzone/Dropzone"
import {Grid, TextField} from "@material-ui/core";

const CreateBannerForm = ({formik, image, setImage, handleChanges, types}) => {

    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={12} xs={12} item>
                <FormControl style={{width: '100px'}}>
                    <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.type ? values.type : types}
                        onChange={handleChanges}
                        error={touched.type && Boolean(errors.type)}
                        helperText={touched.type && errors.type}
                    >
                        <MenuItem value={"Link"}>Link</MenuItem>
                        <MenuItem value={"Notification"}>Notification</MenuItem>

                    </Select>
                </FormControl>
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="title"
                    name="title"
                    label="title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="link"
                    name="link"
                    label="link"
                    value={values.link}
                    onChange={handleChange}
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                />
            </Grid>
            <Grid sm={12} xs={12} item>
                <Dropzone acceptType="image/*" files={image} setFiles={setImage} title="Image"
                          caption="Drag 'n' drop image files here, or click to select image files "/>

            </Grid>
        </Grid>
    );
};

export default CreateBannerForm;
