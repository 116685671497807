import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import {ChannelValidation} from "../../../helper/ValidationSchema";
import CreateChannelsForm from "./CreateChannelsForm";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));

const CreateChannels = ({ChannelsData, editCheck, singleGifts, editChannels, file, setFile, setSingleGifts, image, setImage,}) => {

    const classes = useStyles();
    const [img, setImg] = useState(false);
    const initialValues = {
        title: "",
        link: "",
        image: "",
    };

    const editValues = {
        title: singleGifts && singleGifts.title ? singleGifts.title : "",
        link: singleGifts && singleGifts.link ? singleGifts.link : "",
        image: singleGifts && singleGifts.image ? singleGifts.image : "",
    };

    const handleSubmit = async (values, action) => {
        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("link", values.link);
        formData.append("image", image ? image[0] : values.image);

        const url = "channel";
        const result = await helpers.apiCallForm("POST", url, formData);
        if (result.status === 200) {
            ChannelsData();
            setImg(true);
        }
        setImg(false);
    };

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editChannels : handleSubmit}
                validationSchema={
                    ChannelValidation
                }
            >
                {(formik) => {
                    return (
                        <Form autoComplete="off">
                            <CreateChannelsForm
                                formik={formik}
                                setFile={setFile}
                                file={file}
                                singleGifts={singleGifts}
                                setSingleGifts={setSingleGifts}
                                image={image}
                                setImage={setImage}
                                img={img}
                            />
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "Edit" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreateChannels;
