import React, {useEffect, useState, useCallback} from "react"
import {helpers} from '../../helper/helper';
import Card from "../../layouts/Card";
import Grid from "@material-ui/core/Grid";
import Users from "../Users";

const Dashboard = () => {
    const [dashboard, setDashboard] = useState();

    const DashboardData = useCallback(async () => {
        const url = "devices";
        const result = await helpers.apiCall('GET', url);
        setDashboard(result.data);
    }, [setDashboard]);

    useEffect(() => {
        DashboardData()
    }, [DashboardData]);
    return (
        <section id="team" className="team">
            <div className="container" data-aos="fade-up">
                <Grid container spacing={3}>
                    <Card colors={"#17a2b8"}
                          number={dashboard && dashboard.result && dashboard.result.installed ? dashboard.result.installed : ''}
                          carddata={"Installed Device"}/>
                    <Card colors={"#dc3545"}
                          number={dashboard && dashboard.result && dashboard.result.activeUser ? dashboard.result.activeUser : ''}
                          carddata={"Active Device"}/>
                    <Card colors={"#28a745"}
                          number={dashboard && dashboard.result && dashboard.result.activeUser ? dashboard.result.activeUser : ''}
                          carddata={"Total Device"}/>
                </Grid>
                <br/>
                <br/>
                <div style={{border: '0.5px solid grey', borderRadius: '10px'}}>
                    <Users/>
                </div>
            </div>
        </section>
    )
};

export default Dashboard;