import React, {useEffect, useState} from "react";

import PageContainer from "../../layouts/PageContainer";
import {helpers} from '../../helper/helper';
import {useCallback} from "react";
import UsersLists from "./UserLists";
import Loader from "../../layouts/Loader";

const GiveAway = () => {
    const [userList, setUserList] = useState();
    const UsersData = useCallback(async () => {
        const url = "users";
        const result = await helpers.apiCall('GET', url);
        setUserList(result.data);
    }, [setUserList]);

    useEffect(() => {
        UsersData()
    }, [UsersData]);

    return (
        <PageContainer pageheading="USER LIST">
            {!userList ? <Loader/> :
                <UsersLists userList={userList}/>
            }
        </PageContainer>
    )
};

export default GiveAway;