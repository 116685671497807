import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import CreateZole from "./CreateZole";
import {Grid} from "@material-ui/core";
import ZoleList from "./ZoleList";
import {helpers} from "../../helper/helper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useSnackbar} from "notistack";

const Zole = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [zoleList, setZoleList] = useState();
    const [check, setCheck] = useState(false);
    const [singlePlan, setSinglePlan] = useState();
    const [planId, setPlanId] = useState();
    const [editCheck, setEditCheck] = useState(false);

    const PlanData = useCallback(async () => {
        const url = "zoles";
        const result = await helpers.apiCall("GET", url);
        setZoleList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setZoleList]);

    useEffect(() => {
        PlanData();
    }, [PlanData]);

    const editPlan = async (values) => {
        const url = `zoles/${planId}`;
        const result = await helpers.apiCall("PUT", url, values);
        if (result.status === 200) {
            PlanData();
            setEditCheck(false)
            enqueueSnackbar("Updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singlePlanById = async (id) => {
        setPlanId(id);
        const url = `zoles/${id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSinglePlan(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    };

    const deletePlan = async (id) => {
        const url = `zoles/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            PlanData();
            enqueueSnackbar("Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    const handleChange = () => {
        setCheck(!check);
    };

    return (
        <PageContainer pageheading="CREATE ZOLE">
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <CreateZole
                        PlanData={PlanData}
                        editCheck={editCheck}
                        singlePlan={singlePlan}
                        editPlan={editPlan}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={check} onChange={handleChange} name="check"/>
                            }
                            label="Show Plan List"
                        />
                    </FormGroup>

                    <ZoleList
                        planList={zoleList}
                        deletePlan={deletePlan}
                        editPlan={editPlan}
                        singlePlanById={singlePlanById}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Zole;
