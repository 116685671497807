import React from "react";
import {Container, Typography, Avatar, Button} from "@material-ui/core";
import ForgetForm from "./ForgetForm";
import {Form, Formik} from "formik";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {helpers} from '../../../helper/helper';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ForgetPassword = () => {
    const classes = useStyles();
    const initialValues = {
        methode: "email",
        email: "",
        password: "",
        phone: "",
    };

    const handleSubmit = async (values) => {
        const email = {email: values.email, password: values.password};
        const phone = {mobile: values.phone, password: values.password};

        if (values.methode === "email") {
            const url = "users/forgot-password";
            await helpers.api("POST", url, email)

        } else if (values.methode === "phone") {
            const url = "users/forgot-password";
            await helpers.api("POST", url, phone)
        }
    };

    const handleOtp = async (values, condition) => {
        const email = {email: values};
        const phone = {mobile: values};
        const url = "users/verify-account";
        if (condition.methode === "email") {
            await helpers.api("POST", url, email)
        } else if (condition.methode === "phone") {
            await helpers.api("POST", url, phone)
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forget Password
                </Typography>
                <br/>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete>
                                <div className={classes.form} noValidate>
                                    <ForgetForm formik={formik} handleOtp={handleOtp}/>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Container>
    );
};

export default ForgetPassword;
