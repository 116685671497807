import React from "react";
import Dropzone from "../../../layouts/Dropzone/Dropzone";
import {Grid, TextField} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const CreateTaskForm = ({formik, image, setImage, handleChanges, rewardTypes,}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={12} xs={12} item>
                <FormControl style={{width: "100%"}}>
                    <InputLabel id="demo-simple-select-label">
                        Select Reward Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rewardTypes ? rewardTypes : values.rewardType}
                        onChange={handleChanges}
                        error={touched.rewardType && Boolean(errors.rewardType)}
                        helperText={touched.rewardType && errors.rewardType}
                    >
                        <MenuItem value={"daily"}>Daily</MenuItem>
                        <MenuItem value={"weekly"}>Weekly</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="link"
                    name="link"
                    label="Link"
                    value={values.link}
                    onChange={handleChange}
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                />
            </Grid>
            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="reward"
                    name="reward"
                    label="Reward"
                    value={values.reward}
                    onChange={handleChange}
                    error={touched.reward && Boolean(errors.reward)}
                    helperText={touched.reward && errors.reward}
                />
            </Grid>
            <Grid sm={12} xs={12} item>
                <Dropzone
                    acceptType="image/*"
                    files={image}
                    setFiles={setImage}
                    imgSrc={image && image.length ? "" : values.image ? values.image : ""}
                    title="Image"
                    caption="Drag 'n' drop image files here, or click to select image files "
                />
            </Grid>
        </Grid>
    );
};

export default CreateTaskForm;
