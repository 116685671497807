import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Paper, Button} from "@material-ui/core";
import {Formik, Form} from "formik";
import {helpers} from "../../../helper/helper";
import {PlanValidation} from "../../../helper/ValidationSchema";
import CreatePlanForm from "./CreatePlanForm";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        },
    },
    submit: {
        margin: theme.spacing(4, 0, 1, 0),
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "91%",
    },
}));


const CreatePlan = ({PlanData, editCheck, singlePlan, editPlan}) => {

    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const initialValues = {
        amount: "",
        validity: "",
        bonus: "",
    };

    const editValues = {
        amount: singlePlan && singlePlan.amount ? singlePlan.amount : '',
        validity: singlePlan && singlePlan.validity ? singlePlan.validity : '',
        bonus: singlePlan && singlePlan.bonus ? singlePlan.bonus : '',
    };

    const handleSubmit = async (values) => {

        const url = "plans";
        const result = await helpers.apiCall("POST", url, values);
        if (result.status === 200) {
            PlanData();
            enqueueSnackbar("Plan Created successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        } else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    return (
        <Paper className={classes.paper} square>
            <Formik
                enableReinitialize="true"
                initialValues={editCheck ? editValues : initialValues}
                onSubmit={editCheck ? editPlan : handleSubmit}
                validationSchema={
                    PlanValidation
                }
            >
                {(formik) => {
                    return (
                        <Form autoComplete="off">
                            <CreatePlanForm formik={formik}/>
                            <Grid align="right" item style={{marginTop: "10px"}}>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className={classes.submit}
                                        color="primary"
                                    >
                                        {editCheck ? "Edit" : "Create"}
                                    </Button>
                                </div>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Paper>
    );
};

export default CreatePlan;
