import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import {Grid} from "@material-ui/core";
import {helpers} from "../../helper/helper";
import FeedbackList from "./FeedbackList";

const Feedback = () => {
    const [feedbackList, setFeedbackList] = useState();
    const FeedbackData = useCallback(async () => {
        const url = "feedback";
        const result = await helpers.apiCall("GET", url);
        setFeedbackList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setFeedbackList]);

    useEffect(() => {
        FeedbackData();
    }, [FeedbackData]);

    return (
        <PageContainer pageheading="FEEDBACK">
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <FeedbackList feedbackList={feedbackList}/>
                </Grid>
            </Grid>
        </PageContainer>
    )
};

export default Feedback;