import React from "react";
import {LinearProgress, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Link} from "react-router-dom"

const sortIcon = <ArrowDownward/>;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        table: {
            minWidth: 650,
        },
    },
}));

const FeedbackList = ({feedbackList}) => {
    const classes = useStyles();

    const heading = [
        {
            name: "Sr. No",
            wrap: true,
            sortable: true,
            cell: (row, index) => index + 1,
        }, {
            name: "Reported by",
            wrap: true,
            sortable: true,
            cell: (feedbackList) => (<div>
                <p>{feedbackList && feedbackList.reportedby && feedbackList.reportedby.email ? feedbackList.reportedby.email : ''}</p>
            </div>)
        }, {
            name: "Title",
            selector: "title",
            wrap: true,
            sortable: true,
        }, {
            name: "status",
            selector: "status",
            wrap: true,
            sortable: true,
            cell: (feedbackList) => (<div>
                <p style={feedbackList && feedbackList.status?{color:"green"}:{color:"red"}}>{feedbackList && feedbackList.status ? (feedbackList.status  ? "RESOLVED" : '') : ' NOT RESOLVED'}</p>
            </div>)
        }, {
            name: " ",
            selector: "_id",
            right: true,
            cell: (feedbackList) => (
                <Link to={"/dashboard/feedback/" + feedbackList._id}>
                    <ChevronRightIcon/>
                </Link>
            )
        }
    ];

    return (
        <div>
            {!feedbackList ? (
                <LinearProgress color="primary"/>
            ) : (

                <Paper className={classes.paper} square>
                    {feedbackList ?
                        <DataTable
                            columns={heading}
                            noHeader={true}
                            data={feedbackList}
                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            sortIcon={sortIcon}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                        />
                        : ''}
                </Paper>
            )}
        </div>
    );
};

export default FeedbackList;
