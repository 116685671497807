import React from "react";
import Dropzone from "../../../layouts/Dropzone/Dropzone"
import {Grid, TextField} from "@material-ui/core";

const CreateChannelsForm = ({formik, image, setImage}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="title"
                    name="title"
                    label="title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="link"
                    name="link"
                    label="link"
                    value={values.link}
                    onChange={handleChange}
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                />
            </Grid>
            <Grid sm={12} xs={12} item>
                <Dropzone acceptType="image/*" files={image} setFiles={setImage}
                          imgSrc={image && image.length ? "" : values.image ? values.image : ''} title="Image"
                          caption="Drag 'n' drop image files here, or click to select image files "/>

            </Grid>
        </Grid>
    );
};

export default CreateChannelsForm;
