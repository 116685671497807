import React, {useState} from "react";
import {Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchLayout from "../../layouts/SearchLayout";

const sortIcon = <ArrowDownward/>;

const PaymentHistoryList = ({historyList}) => {
    const [filterText, setFilterText] = useState("");

    const filteredItems =
        historyList
            ? historyList.filter((item) => {
                let search = filterText.toLowerCase();
                return item._id && item._id.toLowerCase().includes(search)
            })
            : "";

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <SearchLayout onFilter={setFilterText} placeHolderText="Search by Name"/>
        );
    }, [setFilterText]);
    const headingAdmin = [
        {
            name: "Id",
            selector: "_id",
            wrap: true,
            sortable: true,
        }, {
            name: "Name",
            wrap: true,
            sortable: true,
            cell: (historyList, index) =>
                (
                    <p>{historyList.userId.name}</p>
                )
        }, {
            name: "PlatFrom",
            selector: "platform",
            wrap: true,
            sortable: true,
        }, {
            name: "Email",
            wrap: true,
            sortable: true,
            cell: (historyList, index) =>
                (
                    <p>{historyList.userId.email}</p>
                )
        }, {
            name: " ",
            selector: "_id",
            right: true,
            cell: (historyList) => (
                <Link to={"/dashboard/payment-history/" + historyList._id}>
                    <ChevronRightIcon/>
                </Link>
            )
        }
    ];

    return (
        <DataTable
            columns={headingAdmin}
            data={filteredItems}
            defaultSortField="name"
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            sortIcon={sortIcon}
            subHeader
            noHeader={true}
            subHeaderWrap={false}
            subHeaderAlign="right"
            subHeaderComponent={[subHeaderComponentMemo]}
            striped={true}
            highlightOnHover={true}
            responsive={true}
        />
    );
};
export default PaymentHistoryList;
