import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";
import CreateGifts from "./CreateGifts";
import {Grid} from "@material-ui/core";
import GiftsList from "./GiftsList";
import {helpers} from "../../helper/helper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useSnackbar} from "notistack";

const Gifts = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [giftsList, setGiftsList] = useState();
    const [check, setCheck] = useState(true);
    const [singleGifts, setSingleGifts] = useState();
    const [giftsId, setGiftsId] = useState();
    const [editCheck, setEditCheck] = useState(false);
    const [file, setFile] = useState();
    const [image, setImage] = useState([]);

    const GiftsData = useCallback(async () => {
        const url = "gifts";
        const result = await helpers.apiCall("GET", url);
        setGiftsList(
            result && result.data && result.data.result ? result.data.result : []
        );
    }, [setGiftsList]);

    useEffect(() => {
        GiftsData();
    }, [GiftsData]);

    const editGifts = async (values, {resetForm}) => {
        let formData = new FormData();
        formData.append('zole', values.zole);
        formData.append('popularity', values.popularity);
        formData.append('giftImg', image[0] ? image[0] : singleGifts.giftImg);
        const url = `gifts/${giftsId}`;
        const result = await helpers.apiCallForm("PUT", url, formData);
        if (result.status === 200) {
            GiftsData();
            setEditCheck(false);
            setSingleGifts();
            setImage([]);
            enqueueSnackbar("Gifts Updated successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        } else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };

    const singleGiftsById = async (id) => {
        setGiftsId(id);
        const url = `Gifts/${id}`;
        const result = await helpers.apiCall("get", url);
        if (result.status === 200) {
            setSingleGifts(result.data.result);
            setEditCheck(true);
        } else {
            setEditCheck(false);
        }
    };

    const deleteGifts = async (id) => {
        const url = `Gifts/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            GiftsData();
            enqueueSnackbar("Gifts Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        } else {
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    const handleChange = () => {
        setCheck(!check);
    };

    return (
        <PageContainer pageheading="GIFTS">
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <CreateGifts
                        GiftsData={GiftsData}
                        editCheck={editCheck}
                        singleGifts={singleGifts}
                        editGifts={editGifts}
                        setFile={setFile}
                        file={file}
                        setSingleGifts={setSingleGifts}
                        image={image}
                        setImage={setImage}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Gifts List"
                        />
                    </FormGroup>

                    <GiftsList
                        giftsList={giftsList}
                        deleteGifts={deleteGifts}
                        editGifts={editGifts}
                        singleGiftsById={singleGiftsById}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Gifts;
