import React from "react";
import {Grid, TextField} from "@material-ui/core";

const CreateSubscriptionForm = ({formik}) => {
    const {handleChange, values, touched, errors} = formik;

    return (
        <Grid container spacing={3}>
            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="amount"
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    onChange={handleChange}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                />
            </Grid>

            <Grid sm={12} xs={12} item>
                <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="timePeriod"
                    name="timePeriod"
                    label="TimePeriod in Days"
                    value={values.timePeriod}
                    onChange={handleChange}
                    error={touched.timePeriod && Boolean(errors.timePeriod)}
                    helperText={touched.timePeriod && errors.timePeriod}
                />
            </Grid>
        </Grid>
    );
};

export default CreateSubscriptionForm;
